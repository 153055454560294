<template>
  <!--Edit with components from https://vue3-google-map.netlify.app/components/ -->
  <!-- Configuration and option values found in Google maps documentation https://developers.google.com/maps/documentation/javascript -->
  <GoogleMap
    api-key="AIzaSyCWllD35fUNznv4rkuHztTSWz-B0glDAII"
    style="width: 100%; height: 300px; margin-bottom: 0%; margin-top: 0%"
    :center="observation"
    :zoom="6"
  >
    <Marker
      id="2"
      @dragend="updateObservedCoordinates"
      :options="{
        position: observation,
        draggable: true,
        label: 'Din posisjon',
      }"
    />
    <Marker
      @dragend="updateStartCoordinates"
      :options="{ position: start, draggable: true, label: 'Start' }"
    />
    <Marker
      @dragend="updateFinishCoordinates"
      :options="{ position: finish, draggable: true, label: 'Slutt' }"
    />
  </GoogleMap>
  <p class=""><small>"Din posisjon" representerer stedet du stod da du gjorde observasjonen.<br>De to andre nålene representerer omtrentlig område der du så meteoren starte og slutte. <br> Skjemaet oppdaterer seg når du er ferdig med å flytte nålene.</small></p>
  <div class="positionCoords">
    <p v-if="updateObservedCoordinates">
      Din posisjon: {{ observedCoordinates.lat }}
      {{ observedCoordinates.lng }}
    </p>
    <p v-if="updateStartCoordinates">
      Start-koordinater: {{ startCoordinates.lat }}
      {{ startCoordinates.lng }}
    </p>
    <p v-if="updateFinishCoordinates">
      Slutt-koordinater: {{ finishCoordinates.lat }}
      {{ finishCoordinates.lng }}
    </p>
    <p v-if="getBearingGeodesic">
      Bearing(Himmelretning) 1 :
      {{ getBearingStart(observedCoordinates, startCoordinates) }} grader.
    </p>
    <p v-if="getBearingGeodesic">
      Bearing(Himmelretning) 2 :
      {{ getBearingFinish(observedCoordinates, finishCoordinates) }} grader.
    </p>
    <p>Test for å sjekke start GOOGLEMAP KOMPONENT {{ bearingStartChild }}</p>
    <p>Test for å sjekke finish GOOGLEMAP KOMPONENT {{ bearingFinishChild }}</p>
    <p>Test for å sjekke bearingsChild {{ bearingsChild }}</p>
  </div>
</template>

<script>
/**
 * Setup() set initial values for markers so they will load on map. Used in <Marker> options object.
 * @param { Object } Updatecoordinates, one for each marker takes in location given from marker and returns lat, lng and sets them to corresponding empty object.
 * @param {Object, Object} getBearingGeodesic takes inn two objects, each containing lat / lng and returns geodesic bearing.
 */
import { defineComponent } from 'vue'
import { GoogleMap, Marker } from 'vue3-google-map'

export default
  defineComponent({
  components: { GoogleMap, Marker },
  data() {
    return {
      // objects used to calculate
      startCoordinates: {},
      observedCoordinates: {},
      finishCoordinates: {},
      bearing: 1337,
      bearingStartChild: 0,
      bearingFinishChild: 0,
      bearingsChild: [],
    }
  },
  setup() {
    // initial location for markers in map
    const observation = { lat: 60.01846109243557, lng: 9.026502326856768 }
    const start = { lat: 60.49278633247959, lng: 8.627963120454432 }
    const finish = { lat: 60.51707293219277, lng: 9.482429613897185 }
    return { observation, start, finish }
  },
  created() {
    this.bearingsChild = this.bearings
  },
  props: {
    bearings: {
      type: Array,
    },
  },
  methods: {
    updateStartCoordinates(location) {
      this.startCoordinates = {
        lat: location.latLng.lat(),
        lng: location.latLng.lng(),
      }
    },
    updateObservedCoordinates(location) {
      this.observedCoordinates = {
        lat: location.latLng.lat(),
        lng: location.latLng.lng(),
      }
      this.bearingsChild[2] = this.observedCoordinates.lat
      this.bearingsChild[3] = this.observedCoordinates.lng
    },
    updateFinishCoordinates(location) {
      this.finishCoordinates = {
        lat: location.latLng.lat(),
        lng: location.latLng.lng(),
      }
    },
    getBearingGeodesic(startCords, finishCords) {
      // Sets start lat and long
      let startLat
      let startLong

      startLat = (startCords.lat * Math.PI) / 180
      startLong = (startCords.lng * Math.PI) / 180

      // Sets end lat and long
      let endLat
      let endLong

      endLat = (finishCords.lat * Math.PI) / 180
      endLong = (finishCords.lng * Math.PI) / 180
      var dLong = endLong - startLong

      // Calculates bearing
      var y = Math.sin(dLong) * Math.cos(endLat)
      var x =
        Math.cos(startLat) * Math.sin(endLat) -
        Math.sin(startLat) * Math.cos(endLat) * Math.cos(dLong)
      let bearingCalc = ((Math.atan2(y, x) * 180) / Math.PI + 360.0) % 360.0
      return bearingCalc
    },
    getBearingStart(startCords, finishCords) {
      let startLat
      let startLong

      startLat = (startCords.lat * Math.PI) / 180
      startLong = (startCords.lng * Math.PI) / 180

      // Sets end lat and long
      let endLat
      let endLong

      endLat = (finishCords.lat * Math.PI) / 180
      endLong = (finishCords.lng * Math.PI) / 180
      var dLong = endLong - startLong

      // Calculates bearing
      var y = Math.sin(dLong) * Math.cos(endLat)
      var x =
        Math.cos(startLat) * Math.sin(endLat) -
        Math.sin(startLat) * Math.cos(endLat) * Math.cos(dLong)
      this.bearingStartChild =
        ((Math.atan2(y, x) * 180) / Math.PI + 360.0) % 360.0
      this.bearingsChild[0] = this.bearingStartChild
      this.$emit('update:bearings', this.bearingsChild)
      console.log(`Dette er ${this.bearingStart} 🗺`)
      return this.bearingStart
    },
    getBearingFinish(startCords, finishCords) {
      let startLat
      let startLong

      startLat = (startCords.lat * Math.PI) / 180
      startLong = (startCords.lng * Math.PI) / 180

      // Sets end lat and long
      let endLat
      let endLong

      endLat = (finishCords.lat * Math.PI) / 180
      endLong = (finishCords.lng * Math.PI) / 180
      var dLong = endLong - startLong

      // Calculates bearing
      var y = Math.sin(dLong) * Math.cos(endLat)
      var x =
        Math.cos(startLat) * Math.sin(endLat) -
        Math.sin(startLat) * Math.cos(endLat) * Math.cos(dLong)
      this.bearingFinishChild =
        ((Math.atan2(y, x) * 180) / Math.PI + 360.0) % 360.0
      this.bearingsChild[1] = this.bearingFinishChild
      console.log(`Dette er ${this.bearingFinish} 🗺🗺🗺`)
      return this.bearingFinish
    },
  },
})
</script>

<style scoped>
.positionCoords {
  display: none;
}
</style>
