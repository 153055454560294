<template>
  <!-- Send inn meteor rapport -->
  <div class="card text-white bg-dark mb-3">
    <div class="card-header" style="background-color: #212529">
      <h1 class="display-4" style="color: white; text-align: center">
        <i class="fa-solid fa-meteor"></i> Rapporter ny meteor
      </h1>
    </div>
    <div
      class="card-body"
      style="background-color: #212529; width: 95%; margin: 0 auto"
    >
      <p style="font-color: white">
        Vi er interesserte i all informasjon om ildkuler eller kraftige
        stjerneskudd. Har du sett et stjerneskudd som lyste kraftigere enn Venus
        (dvs. sterkere enn noen synlig stjerne/planet på himmelen), meld fra om
        din observasjon her!
        Ved å sammenstille flere observasjoner kan vi
        beregne banen og vurdere muligheten for nedfall av meteoritter. 
       <u>Din egen posisjon og retninger er den viktigste informasjonen for oss.
        Dette beregnes automatisk og presist når du plasserer kartmarkørene.</u> 
        Fyll ut resten så godt du kan. Trykk gjerne på spørsmålstegnene hvis du er usikker!
        Bruk gjerne kommentarfeltet for å forklare nærmere eller beskrive hva du så!
      </p>
      <b-form @submit="sendMail()" @reset="onReset()" v-if="show">
        <!-- Kontaktinformasjon -->
        <b-form-group id="input-group-1" label="Navn:" label-for="input-1">
          <b-form-input
            id="input-1"
            v-model="form.navn"
            type="text"
            placeholder="Navn (påkrevd)"
            maxlength=30
            style="margin-top: 5px"
            required
          >
          </b-form-input>
        </b-form-group>
        <b-row
          ><b-col>
            <b-form-group
              id="input-group-2"
              label="Telefon:"
              label-for="input-2"
            >
              <b-form-input
                id="input-2"
                v-model="form.telefon"
                type="text"
                maxlength=15
                placeholder="Tlf"
              >
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group
              id="input-group-3"
              label="E-post: "
              label-for="input-3"
            >
              <b-form-input
                id="input-3"
                v-model="form.epost"
                type="email"
                placeholder="E-post (påkrevd)"
                maxlength=50
                required
              >
              </b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <!--Tid og dato -->
        <b-form-group
          id="input-group-4"
          label="Observasjonstidspunkt:  "
          label-for="input-4"
        >
        <!-- Add pattern rule here ? is set in extention setup -->
          <Datepicker
            v-model="form.date"
            :flow="flow"
            placeholder="MM-DD-YYYY, HH:MM (påkrevd)"
            :is24="true"
            locale="no-no"
            cancelText="Avbryt"
            selectText="Sett inn tidspunkt"
            textInput
            required
          /><!--Styling down in script!-->
        </b-form-group>
        <!--GoogleMap.vue, henter inn kart, beregner data som settes inn i skjema -->
        <GoogleMap
          v-model:bearings="bearings"
        ></GoogleMap>
        <!-- Felt for observasjonstdata  -->
        <b-form-group
          id="input-group-5"
          label="Velg din posisjon:  "
          label-for="input-5"
        >
          <b-row>
            <b-col>
              <div class="mt-2">
                <b-form-input
                  v-model="form.latitude"
                  :value="bearings[2]"
                  placeholder="Breddegrad (påkrevd)"
                  minlength=1
                  maxlength=30
                  required
                >
                </b-form-input>
              </div>
            </b-col>
            <b-col>
              <div class="mt-2">
                <b-form-input
                  v-model="form.longitude"
                  :value="bearings[3]"
                  placeholder="Lengdegrad (påkrevd)"
                  minlength=1
                  maxlength=30
                  required
                >
                </b-form-input>
              </div>
            </b-col>
            <h5
              v-b-popover.click="
                'Koordinatene for din posisjon oppdateres når du setter Din posisjon-markøren i kartet.'
              "
              style="width: 3%"
            >
              <i class="fa-solid fa-circle-question"></i>
            </h5>
          </b-row>
        </b-form-group>
        <!--Først sett-->
        <b-form-group
          id="input-group-5"
          label="Først sett:"
          label-for="input-5"
        >
          <b-row>
            <b-col>
              <div class="mt-2">
                <b-form-input
                  v-model="form.firstdirection"
                  type="text"
                  :value="bearings[0]"
                  placeholder="Kompassgrad (påkrevd)"
                  minlength=1
                  maxlength=30
                  required
                  >
                </b-form-input>
              </div>
            </b-col>
            <b-col>
              <div class="mt-2">
                <b-form-input v-model="form.firstheight" placeholder="Høyde" minlength=1 maxlength=20>
                </b-form-input>
              </div>
            </b-col>
            <h5
              v-b-popover.click="
                'Himmelretningen til observasjonen din kalkuleres når du setter start-markøren i kartet, men vil ikke vises i skjemaet før du har satt slutt-markøren. Sett start-markøren din over stedet der du ca. så meteoren starte. Høyde lar du stå åpent hvis du er usikker.'
              "
              style="width: 3%"
            >
              <i class="fa-solid fa-circle-question"></i>
            </h5>
          </b-row>
        </b-form-group>
        <!--Sist sett-->
        <b-form-group id="input-group-6" label="Sist sett:" label-for="input-5">
          <b-row>
            <b-col>
              <div class="mt-2">
                <b-form-input
                  v-model="form.lastdirection"
                  :value="bearings[1]"
                  placeholder="Kompassgrad (påkrevd)"
                  minlength=1
                  maxlength=30
                  required
                  >
                </b-form-input>
              </div>
            </b-col>
            <b-col>
              <div class="mt-2">
                <b-form-input v-model="form.lastheight" placeholder="Høyde" minlength=1 maxlength=20>
                </b-form-input>
              </div>
            </b-col>
            <h5
              v-b-popover.click="
                'Himmelretningen til observasjonen din kalkuleres og settes inn skjemaet når du har satt alle tre markører i kartet. Sett slutt-markøren ditt over stedet der du ca. så meteoren slutte å lyse. Høyde lar du stå åpent hvis du er usikker.'
              "
              style="width: 3%"
            >
              <i class="fa-solid fa-circle-question"></i>
            </h5>
          </b-row>
        </b-form-group>
        <!--Farge-->
        <b-form-group
          id="input-group-15"
          label="Dominerende farge:"
          label-for="input-15"
        >
          <b-container class="bv-example-row" style="margin-top: 5px">
            <b-row>
              <b-col>
                <b-form-radio v-model="form.farge" name="farge" value="Hvit"
                  >Hvit</b-form-radio
                >
                <b-form-radio v-model="form.farge" name="farge" value="Grønn"
                  >Grønn</b-form-radio
                >
              </b-col>
              <b-col>
                <b-form-radio v-model="form.farge" name="farge" value="Blå"
                  >Blå</b-form-radio
                >
                <b-form-radio v-model="form.farge" name="farge" value="Gul"
                  >Gul</b-form-radio
                >
              </b-col>
              <b-col>
                <b-form-radio v-model="form.farge" name="farge" value="Orange"
                  >Orange</b-form-radio
                >
                <b-form-radio v-model="form.farge" name="farge" value="Rød"
                  >Rød</b-form-radio
                >
              </b-col>
              <b-col>
                <b-form-radio v-model="form.farge" name="farge" value="Annen"
                  >Annen</b-form-radio
                >
                <b-form-radio v-model="form.farge" name="farge" value="Usikker"
                  >Usikker</b-form-radio
                >
              </b-col>
            </b-row>
          </b-container>
        </b-form-group>
        <!--Lysstyrke-->
        <b-row>
          <b-col>
            <b-form-group
              id="input-group-16"
              label="Lysstyrke:"
              label-for="input-16"
            >
              <b-form-select
                v-model="form.lysstyrke"
                class="mb-3"
                style="margin-top: 5px"
              >
                <b-form-select-option :value="null"
                  >Velg lysstyrke</b-form-select-option
                >
                <b-form-select-option value="1 - Som de klareste stjernene"
                  >1 - Som de klareste stjernene</b-form-select-option
                >
                <b-form-select-option value="2 - Litt sterkere enn stjernene"
                  >2 - Litt sterkere enn stjernene</b-form-select-option
                >
                <b-form-select-option value="3 - Sterkere enn stjernene, men terrenget lyste ikke
                  opp"
                  >3 - Sterkere enn stjernene, men terrenget lyste ikke
                  opp</b-form-select-option
                >
                <b-form-select-option value="4 - Terrenget lyste opp"
                  >4 - Terrenget lyste opp</b-form-select-option
                >
                <b-form-select-option value="5 - Nesten som dagslys"
                  >5 - Nesten som dagslys</b-form-select-option
                >
                <b-form-select-option value="6 - Fullt dagslys"
                  >6 - Fullt dagslys</b-form-select-option
                >
              </b-form-select>
            </b-form-group>
          </b-col>
          <!-- Varighet -->
          <b-col>
            <b-form-group
              id="input-group-14"
              label="Varighet:"
              label-for="input-14"
            >
              <b-form-select
                v-model="form.varighet"
                class="mb-3"
                style="margin-top: 5px"
              >
                <b-form-select-option :value="null"
                  >Varighet i sekunder</b-form-select-option
                >
                <b-form-select-option value="1 sek">1s</b-form-select-option>
                <b-form-select-option value="2 sek">2s</b-form-select-option>
                <b-form-select-option value="3 sek">3s</b-form-select-option>
                <b-form-select-option value="4 sek">4s</b-form-select-option>
                <b-form-select-option value="5 sek">5s</b-form-select-option>
                <b-form-select-option value="6 sek">6s</b-form-select-option>
                <b-form-select-option value="7 sek">7s</b-form-select-option>
                <b-form-select-option value="8 sek">8s</b-form-select-option>
                <b-form-select-option value="9 sek">9s</b-form-select-option>
                <b-form-select-option value="10 sek">10s</b-form-select-option>
              </b-form-select>
            </b-form-group>
          </b-col>
          <h5
            v-b-popover.click="
              'Legg inn verdier så nøyaktig du klarer. Informasjonen brukes til en helhetlig vurdering av observasjonen.'
            "
            style="width: 3%"
          >
            <i class="fa-solid fa-circle-question"></i>
          </h5>
        </b-row>
        <!--Message -->
        <b-form-group
          id="input-group-17"
          label="Kommentarer: "
          label-for="input-17"
        >
          <b-form-textarea
            id="input-18"
            v-model="form.melding"
            placeholder="Nyttig informasjon for oss kan være informasjon om røykspor, eksplosjoner, lyder, etterglød, om du har foto/video, og andre betraktninger."
            rows="5"
            maxlength=1000
            >
          </b-form-textarea>
        </b-form-group>
        <!-- Send and Reset buttons -->
        <b-button
          :pressed="true"
          type="submit"
          variant="success"
          style="width: 66%; margin-top: 2%; margin-bottom: 2%"
          >Send</b-button
        >
        <b-button
          :pressed="false"
          type="reset"
          variant="outline-light"
          style="width: 31%; margin-left: 3%; margin-top: 2%; margin-bottom: 2%"
          >Nullstill</b-button
        >
          <small>Dette nettstedet er beskyttet av reCAPTCHA og Googles <a href="https://policies.google.com/privacy">personvernregler</a> og <a href="https://policies.google.com/terms">vilkår</a> for bruk gjelder.<br/>
          This site is protected by reCAPTCHA and the Google <a href="https://policies.google.com/privacy">Privacy Policy</a> and <a href="https://policies.google.com/terms">Terms of Service</a> apply.</small>
      </b-form>
    </div>
  </div>
</template>

<!-- Form-script -->
<script>
import { ref } from "vue";
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import GoogleMap from "../../components/GoogleMap.vue";
import UserDataService from "../../services/user.service";

export default {
  components: {
    Datepicker,
    GoogleMap,
  },
  setup() {
    const date = ref();
    const flow = ref(["month", "year", "calendar", "hours", "seconds"]);
    const center = { lat: 35.03331823995629, lng: -111.02161954469747 };
    return {
      date,
      flow,
      center,
    };
  },
  data() {
    return {
      form: {
        navn: "",
        telefon: "",
        epost: "",
        date: "",
        latitude: "",
        longitude: "",
        firstdirection: "",
        firstheight: "",
        lastdirection: "",
        lastheight: "",
        farge: "",
        lysstyrke: "",
        varighet: "",
        melding: "",
      },
      show: true,
      bearings: [],
      latData: ""
    };
  },
  methods: {
    async sendMail() {

      await this.$recaptchaLoaded();
      // Get recaptcha token logged with aciton contactForm 
      const rcToken = await this.$recaptcha("contactForm");


      this.form.firstdirection = this.bearings[0]
      this.form.lastdirection = this.bearings[1]
      this.form.latitude = this.bearings[2]
      this.form.longitude = this.bearings[3]

      let text = "Er du klar for å sende rapporten din?";
      if (confirm(text) == false) {
        return false;
      } else {
      UserDataService.sendReport(this.form, rcToken)
        .then((response) => {
          this.response = response.data;
          console.log(response.data);
          this.alert = "Oppdatert!";          
        })
        .catch((error) => {
          console.log(error.response);
          this.alert = error.response.data.message;
        });

        alert('Gratulerer, rapporten din er nå sendt til Norsk Meteor Nettverk for gjennomgang.')
        return window.location.href = '/';
      }
    },

    onReset(event) {
      event.preventDefault();
      // Reset our form values
      this.form.navn = "";
      this.form.telefon = "";
      this.form.epost = "";
      this.form.date = "";
      this.form.latitude = "";
      this.form.longitude = "";
      this.form.firstdirection = "";
      this.form.firstheight = "";
      this.form.lastdirection = "";
      this.form.lastheight = "";
      this.form.farge = "";
      this.form.lysstyrke = "";
      this.form.varighet = "";
      this.form.melding = "";
      // Trick to reset/clear native browser form validation state
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      });
    },
  },
};
</script>

<style scoped>
.card {
  width: 80%;
  margin: 0 auto;
  margin-top: 3%;
  margin-bottom: 3% !important;
  text-align: justify;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  -moz-box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.3);
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.3);
}

/* Input focus white glow */
.form-control:focus {
  border-color: white;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px white;
}

input:focus:invalid {
  box-shadow: 0 0 5px 5px red;
}

/* Size for mobile phones */
@media only screen and (max-width: 767px) {
  .card {
    width: 100% !important;
  }
}
</style>