import axios from 'axios';
import authHeader from './auth-header';
import getConfig from './config';

const API_URL = getConfig();


class UserService {
  getUsers() {
    return axios.get(API_URL + 'users');
  }

  getUserBoard() {
    return axios.get(API_URL + 'user', { headers: authHeader() });
  }

  getMeteorReviews(userId) {
    let callurl = API_URL.concat("user/", userId, "/details");
    return axios.get(callurl, { headers: authHeader() });
  }

  resetPassword(email, password, passwordResetId) {
    return axios.put(API_URL.concat("passwordresetrequest"), { email, password, passwordResetId }, { headers: authHeader() });
  }

  requestPasswordReset(email) {
    return axios.post(API_URL.concat("passwordresetrequest"), { email }, { headers: authHeader() });
  }

  sendReport(form, rcToken) {
    return axios.post(API_URL.concat("reportmeteor"), { form, rcToken }, { headers: authHeader() });
  }

  sendContactForm(form, rcToken) {
    return axios.post(API_URL.concat("contact"), { form, rcToken }, { headers: authHeader() });
  }

  setTutorialDone(userId) {
    let callurl = API_URL.concat("user/", userId, "/tutorialcomplete");
    return axios.put(callurl, {"tutorialComplete": true}, { headers: authHeader() });
  }

  updateRoleAndLevel(user) {
    let callurl = API_URL.concat("user/", user.id);
    return axios.patch(callurl, user,  { headers: authHeader() });
  } 

  getUsersPaginated(page, perpage, orderby, order) {    
    return axios.get(
      API_URL +
        "users?page="+page+"&limit="+perpage+"&orderby="+orderby+"&order="+order ,  { headers: authHeader() }
    );
  }

}

export default new UserService();